import React from 'react';
import { Row, Col, Button, Card } from 'antd';
import google_play_badge from './assets/images/google_play_badge.png';
import app_store_badge from './assets/images/app_store_badge.svg';
import dual_phones from './assets/images/dual_phones.png';
import single_phone from './assets/images/single_phone.png';
import logo from './assets/images/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* Hero Section */}
      <header className="hero-section">
        <div className="hero-content">
          <div className="logo-title-container">
            <div className="hero-logo-bg">
              <img
                src={logo}
                alt="Cocktail Finder Logo"
                className="hero-logo"
              />
            </div>
            <h1 className="hero-title">Cocktail Finder</h1>
          </div>
        </div>
        <div className="screenshots-text-container">
          <Row gutter={[24, 24]} justify="center">
            <Col xs={24} md={8}>
              <img
                src={dual_phones}
                alt="App Screenshot 1"
                className="screenshot"
              />
            </Col>
            <Col xs={24} md={8}>
              <img
                src={single_phone}
                alt="App Screenshot 2"
                className="screenshot"
              />
            </Col>
            <Col xs={24} md={8} className="download-and-text">
              <p className="hero-description">
                Cocktail Finder brings a new way to discover cocktails. Swipe
                right to save your favorite recipes for offline access, search
                by name or ingredients, and enjoy an ad-light, account-free
                experience. Your next great drink is just a swipe away.
              </p>
              <div className="download-buttons">
                <a
                  href="https://apps.apple.com/us/app/cocktail-finder/id6523413573"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={app_store_badge}
                    alt="Download on the App Store"
                    className="store-badge"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eonindie.cocktails&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={google_play_badge}
                    alt="Get it on Google Play"
                    className="store-badge"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </header>

      {/* Features Section */}
      <section className="features-section">
        <Row gutter={[24, 24]} justify="center">
          <Col xs={24} md={12}>
            <Card bordered={false} className="feature-card">
              <h2>Discover Your Perfect Cocktail</h2>
              <p>
                Explore a wide variety of cocktails tailored to your taste.
                Whether you like it sweet, sour, or strong, Cocktail Finder has
                the perfect drink for you.
              </p>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card bordered={false} className="feature-card">
              <h2>Mix Like a Pro</h2>
              <p>
                Follow easy, step-by-step instructions to craft
                professional-quality cocktails at home. Impress your friends
                with your mixology skills.
              </p>
            </Card>
          </Col>
        </Row>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 Cocktail Finder. All rights reserved.</p>
        <Button
          type="link"
          href="https://github.com/Crysis73/privacy_policies/blob/main/cocktail_finder_privacy_policy.md"
          className="footer-link"
        >
          Privacy Policy
        </Button>
      </footer>
    </div>
  );
}

export default App;
